<template>
  <div class="text-right mb-4">
    <div class="mb-4">
      <strong>Select Date Range:  </strong>
      <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#datePickerModal">
        {{ this.globalTrendDateRange.fromDate | dateFormat }} - {{ this.globalTrendDateRange.toDate | dateFormat }}
      </button>

      <div class="modal fade" id="datePickerModal" tabindex="-1" role="dialog" aria-labelledby="datePickerModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <date-range-picker
                v-on:submit="submittedModal"
                v-on:cancel="cancelledModal"
                :allowCompare=true
                :calendarCount=2
                :defaultRangeSelect=this.globalTrendDateRange
                :defaultRangeSelectCompare=this.globalTrendDateRange2
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import 'bootstrap'
import $ from 'jquery'
import DateRangePicker from '@/components/datePicker/DateRangePicker'
import { mapState, mapActions } from 'vuex'
export default {
  components: { DateRangePicker },
  computed: {
    ...mapState('admin', [
      'globalTrendDateRange',
      'globalTrendDateRange2',
    ]),
  },

  methods: {
    ...mapActions('admin', [
      'updateGlobalTrendDateRange',
      'updateGlobalTrendDateRange2',
    ]),
    moment,

    submittedModal: function(range) {
      this.updateGlobalTrendDateRange({
        label: range.label,
        fromDate: moment(range.startDate).startOf('day'),
        toDate: moment(range.endDate).endOf('day'),
      })
        .then(this.updateGlobalTrendDateRange2({
          label: range.labelCompare,
          fromDate: moment(range.startDateCompare).startOf('day'),
          toDate: moment(range.endDateCompare).endOf('day'),
        }))
        .then(this.closeModal())
        .then(this.$parent.triggerUpdateChart())
    },
    cancelledModal: function() {
      this.closeModal()
    },
    closeModal: function() {
      $('#datePickerModal').modal('hide')
    },
  },

  filters: {
    dateFormat: function(value) {
      return value ? value.format('MM/DD/YYYY') : ''
    }
  }
}
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.min.css';
</style>
