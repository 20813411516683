<template>
  <div>
    <util-date-type-picker />
    <util-date-range-compare-picker />

    <div class="row">
      <div class="col-xl-4">
        <div class="cui__utils__heading">
          <strong>Participants</strong>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="font-weight-bold font-size-70 text-primary text-center">{{calcCardSenders}}</div>
            <span v-html="calcCardSendersChange"></span>
          </div>
        </div>
      </div>

      <div class="col-xl-4">
        <div class="cui__utils__heading">
          <strong>Total Active Users</strong>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="font-weight-bold font-size-70 text-primary text-center">{{calcTotalActiveUsers}}</div>
            <span v-html="calcTotalActiveUsersChange"></span>
          </div>
        </div>
      </div>

      <div class="col-xl-4">
        <div class="cui__utils__heading">
          <strong>Participation Percentage</strong>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="font-weight-bold font-size-70 text-primary text-center">
              {{calcParticipationPercentage}}<sup class="font-size-40">%</sup>
            </div>
            <span v-html="calcParticipationPercentageChange"></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>

    <div class="cui__utils__heading">
      <strong>Top Participants</strong>
      <div class="text-right mb-1">
        <download-json-csv :data="csvItems" :name="csvFileName" :labels="csvHeader" :fields="csvFieldsToInclude">
          <!-- :data="items"/filteredItems :fields="csvFieldsToInclude" -->
          <b-button variant="primary" size="md" type="submit">Download Report</b-button>
        </download-json-csv>
      </div>
    </div>
    <template>
      <div>
        <b-card no-body>
          <!-- User Interface controls -->
          <b-card-header class="bg-light">
            <b-row>
              <b-col md="8" xl="5">
                <b-input-group>
                  <b-form-input v-model="filter" class="form-control" placeholder="Search"></b-form-input>
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>

              <b-col md="4" xl="2" class="offset-xl-5">
                <b-form-group label-cols-sm="4" label-cols-xl="6" label="Per page" class="mb-0">
                  <b-form-select v-model="perPage" :options="pageOptions" class="form-control"></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-header>

          <!-- Main table element -->
          <b-table
            class="card-table"
            show-empty
            stacked="md"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
          >
            <template slot="name" slot-scope="row">{{ row.value.first }} {{ row.value.last }}</template>

            <template slot="isActive" slot-scope="row">{{ row.value ? 'Yes :)' : 'No :(' }}</template>

            <template slot="actions" slot-scope="row">
              <b-button
                size="sm"
                @click="info(row.item, row.index, $event.target)"
                class="mr-1"
              >Info modal</b-button>
              <b-button
                size="sm"
                @click="row.toggleDetails"
              >{{ row.detailsShowing ? 'Hide' : 'Show' }} Details</b-button>
            </template>

            <template slot="row-details" slot-scope="row">
              <b-card>
                <ul>
                  <li v-for="(value, key) in row.item" :key="key">test{{ key }}: {{ value }}</li>
                </ul>
              </b-card>
            </template>

            <template #cell(change)="data">
              <span v-html="data.value"></span>
            </template>
          </b-table>
          <b-card-footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="my-0"
            ></b-pagination>
          </b-card-footer>
        </b-card>

        <!-- Info modal -->
        <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
          <pre>{{ infoModal.content }}</pre>
        </b-modal>
      </div>
    </template>

  </div>
</template>

<script>
import DateTypePicker from './DateTypePicker'
import DateRangeComparePicker from './DateRangeComparePickerHelper'
import JsonCSV from 'vue-json-csv'
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  name: 'ParticipationTrend',
  components: {
    'util-date-type-picker': DateTypePicker,
    'util-date-range-compare-picker': DateRangeComparePicker,
    'download-json-csv': JsonCSV,
  },
  data() {
    const calcCardSenders = 0
    const calcTotalActiveUsers = 0
    const calcParticipationPercentage = 0
    const calcCardSendersChange = ''
    const calcTotalActiveUsersChange = ''
    const calcParticipationPercentageChange = ''

    return {
      calcCardSenders,
      calcTotalActiveUsers,
      calcParticipationPercentage,
      calcCardSendersChange,
      calcTotalActiveUsersChange,
      calcParticipationPercentageChange,
      items: [],
      fields: [
        { key: 'email', label: 'E-Mail', sortable: true },
        { key: 'fName', label: 'First Name', sortable: true },
        { key: 'lName', label: 'Last Name', sortable: true },
        { key: 'tPeriod', label: 'Cards This Period', sortable: true, sortDirection: 'desc', class: 'text-center' },
        { key: 'lPeriod', label: 'Cards Last Period', sortable: true, class: 'text-center' },
        { key: 'change', label: 'Change', sortable: true, class: 'text-center' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      // download-json-csv
      csvItems: [],
      csvFileName: 'ParticipationTrend.csv',
      csvHeader: {
        email: 'Sender Email',
        fName: 'Sender First Name',
        lName: 'Sender Last Name',
        tPeriod: 'Cards This Period',
        lPeriod: 'Cards Last Period',
      },
      csvFieldsToInclude: ['email', 'fName', 'lName', 'tPeriod', 'lPeriod'],
    }
  },
  computed: {
    ...mapState('admin', [
      'orderedProductsDetails',
      'orderedProductsDetails2',
      'globalTrendDateRange',
      'globalTrendDateRange2',
      'activeUsersCount',
      'activeUsersCount2',
      'globalClientSelected',
      'globalDateTypeSelected',
    ]),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
  },
  methods: {
    ...mapActions('admin', [
      'fetchOrderedProductDetails',
      'fetchEnabledUsersCount',
    ]),
    ...mapMutations('admin', [
      'startLoading',
      'stopLoading',
    ]),
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.csvItems = filteredItems // Adding this to update the download json csv fields
    },

    triggerUpdateChart() {
      this.startLoading()
      this.calcCardSenders = 0
      this.calcTotalActiveUsers = 0
      this.calcParticipationPercentage = 0
      this.calcCardSendersChange = ''
      this.calcTotalActiveUsersChange = ''
      this.calcParticipationPercentageChange = ''
      let range1Orders = []
      let range2Orders = []

      const selectFilter = '(count,total,hits.(data.(creation_date,customer_info.(customer_name,customer_no,email),product_items.(quantity))))'
      let userFilter = '?limit=1&filter=enabled eq true and population.id eq "' + this.globalClientSelected + '"'
      if (this.globalClientSelected === 'All') {
        userFilter = '?limit=1&filter=enabled eq true'
      }

      Promise.all([
        this.fetchOrderedProductDetails({
          saveToStateObj: 'setOrderedProductsDetails',
          selectFilter: selectFilter,
          dateType: this.globalDateTypeSelected,
          fromDate: this.globalTrendDateRange.fromDate,
          toDate: this.globalTrendDateRange.toDate,
        }),
        this.fetchOrderedProductDetails({
          saveToStateObj: 'setOrderedProductsDetails2',
          selectFilter: selectFilter,
          dateType: this.globalDateTypeSelected,
          fromDate: this.globalTrendDateRange2.fromDate,
          toDate: this.globalTrendDateRange2.toDate,
          tempBatchObj2: true,
        }),
        this.fetchEnabledUsersCount({
          saveToStateObj: 'setActiveUsersCount',
          filter: userFilter,
        }),
        this.fetchEnabledUsersCount({
          saveToStateObj: 'setActiveUsersCount2',
          filter: userFilter,
        }),
      ])
        .then(() => { range1Orders = this.getOrderedProductsByUser(this.orderedProductsDetails) })
        .then(() => { range2Orders = this.getOrderedProductsByUser(this.orderedProductsDetails2) })
        .then(() => this.calculateValues(this.orderedProductsDetails, this.orderedProductsDetails2, this.activeUsersCount, this.activeUsersCount2))
        .then(() => this.updateChart(range1Orders, range2Orders))
        .then(() => this.stopLoading())
    },

    updateChart(range1Orders, range2Orders) {
      // Sorting users based on higher quantity
      range1Orders.sort((a, b) => b.qty - a.qty)

      // range1Orders.length = 10
      const details = []
      range1Orders.forEach(order => {
        let change = '-'
        let lPeriod = 0
        const found = range2Orders.find(r2 => r2.email === order.email)
        if (found) {
          lPeriod = found.qty
          change = (((order.qty - found.qty) / found.qty) * 100).toFixed(1)
        } else {
          lPeriod = 0
          change = (order.qty * 100).toFixed(1)
        }
        if (change < 0) {
          change = "<div class='text-danger'>" + change + "% <i class='fe fe-arrow-down-circle'></i></div>"
        } else {
          change = "<div class='text-success'>" + change + "% <i class='fe fe-arrow-up-circle'></i></div>"
        }
        details.push({ fName: order.fName, lName: order.lName, email: order.email, tPeriod: order.qty, lPeriod: lPeriod, change: change })
      })

      this.items = details
      this.totalRows = details.length
      this.csvItems = details
    },

    getOrderedProductsByUser(orderedDetails) {
      const orderedProducts = orderedDetails || []
      const groupOrdersByUser = []

      orderedProducts.reduce((res, value) => {
        const custNo = value.data.customer_info.customer_no
        const custName = value.data.customer_info.customer_name?.split(' ')
        const prodItems = value.data.product_items || []
        const email = value.data.customer_info.email
        prodItems.forEach((prodItem) => {
          if (!res[email]) {
            res[email] = { userId: custNo, email: email, fName: custName[0], lName: custName[1], qty: 0 }
            groupOrdersByUser.push(res[email])
          }
          res[email].qty += prodItem.quantity
        })
        return res
      }, {})

      return groupOrdersByUser
    },

    calculateValues(orderedDetails, orderedDetails2, activeUsersCount, activeUsersCount2) {

      this.calcCardSenders = this.getUniqueUsersCount(orderedDetails)
      const cardSendersEarlier = this.getUniqueUsersCount(orderedDetails2)
      if (cardSendersEarlier > 0) {
        const CardSendersChange = (((this.calcCardSenders - cardSendersEarlier) / cardSendersEarlier) * 100).toFixed(1)
        if (CardSendersChange < 0) {
          this.calcCardSendersChange = "<div class='text-danger text-right'>" + CardSendersChange + "% <i class='fe fe-arrow-down-circle'></i></div>"
        } else {
          this.calcCardSendersChange = "<div class='text-success text-right'>" + CardSendersChange + "% <i class='fe fe-arrow-up-circle'></i></div>"
        }
      }

      if (activeUsersCount > 0) {
        this.calcTotalActiveUsers = activeUsersCount
        this.calcParticipationPercentage = ((this.calcCardSenders * 100) / activeUsersCount).toFixed(1)
      }

      if (activeUsersCount2 > 0) {
        const totalActiveUsersChange = (((activeUsersCount - activeUsersCount2) / activeUsersCount2) * 100).toFixed(1)
        if (totalActiveUsersChange < 0) {
          this.calcTotalActiveUsersChange = "<div class='text-danger text-right'>" + totalActiveUsersChange + "% <i class='fe fe-arrow-down-circle'></i></div>"
        } else {
          this.calcTotalActiveUsersChange = "<div class='text-success text-right'>" + totalActiveUsersChange + "% <i class='fe fe-arrow-up-circle'></i></div>"
        }

        const participationEarlier = ((cardSendersEarlier * 100) / activeUsersCount2).toFixed(1)
        if (participationEarlier > 0) {
          const percentageChange = (((this.calcParticipationPercentage - participationEarlier) / participationEarlier) * 100).toFixed(1)
          if (percentageChange < 0) {
            this.calcParticipationPercentageChange = "<div class='text-danger text-right'><i class='fe fe-arrow-down-circle'></i></div>"
          } else {
            this.calcParticipationPercentageChange = "<div class='text-success text-right'><i class='fe fe-arrow-up-circle'></i></div>"
          }
        }
      }
    },

    getUniqueUsersCount(orders) {
      const userArray = []
      orders.forEach((order) => {
        userArray.push(order.data.customer_info.customer_no)
      })
      return this.countUnique(userArray)
    },
    countUnique(iterable) {
      return new Set(iterable).size;
    },
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.triggerUpdateChart()
  },

  watch: {
    globalClientSelected() {
      this.triggerUpdateChart()
    }
  },
}
</script>
